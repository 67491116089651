import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import bitcoinImg from "../../assets/image/jpeg/bitcoin.jpg";
import tradingImg from "../../assets/image/jpeg/trading.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 400px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <Title variant="hero">
              Adapting traditional financial instruments
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5">
            <Text>
              A focus of our investments and technology are adapting proven traditional instruments
              for the blockchain and DeFi ecosystem. We're also interested in projects and engineers that 
              target inefficient markets, volatility and real-world assets. 
              <br className="d-none d-md-block" />
              <br className="d-none d-md-block" />
              We avoid projects with near-term vision and short-term
              profit-taking schemes.
            </Text>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4" sm="5" className="mb-4 ">
            <ContentImg>
              <img src={bitcoinImg} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <img src={tradingImg} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
