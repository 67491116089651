import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";
import CTA from "../sections/about/CTA";

const About = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero title="Our Mission">
          We believe blockchain and decentralization are here to stay. That's why we invest in and build technology to endure.
        </Hero>
        <Content />
      </PageWrapper>
    </>
  );
};
export default About;
